import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import { AllArticles, EssentialArticles, NewArticles } from 'components/Article';
import TopBanner from 'components/TopBanner';

import { ArticleTypes } from '../../@types/content/article';

const ArticleListPage: FC<ArticleTypes.ArticleListPageProps> = ({
  data: {
    articleListPage: {
      urls,
      seo,
      newArticles,
      essentialArticles,
      newArticleHeadingText,
      essentialArticlesHeadingText,
      allArticlesHeadingText,
      topBanner,
    },
    allArticlePage: { nodes: allArticles },
  },
}) => {
  const mappedArticles = allArticles?.map(({ article }) => article);

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <TopBanner topBanner={topBanner} />
      <NewArticles articles={newArticles} newArticleHeadingText={newArticleHeadingText} />
      <EssentialArticles
        articles={essentialArticles}
        essentialArticlesHeadingText={essentialArticlesHeadingText}
      />
      <AllArticles articles={mappedArticles} allArticlesHeadingText={allArticlesHeadingText} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    allArticlePage {
      nodes {
        article {
          ...ArticleFragment
        }
      }
    }
    articleListPage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      essentialArticles {
        ...ArticleFragment
      }
      newArticles {
        ...ArticleFragment
      }
      topBanner {
        ...TopBannerFragment
      }
      newArticleHeadingText
      essentialArticlesHeadingText
      allArticlesHeadingText
    }
  }
`;

export default ArticleListPage;
